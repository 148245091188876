/**
 * Implement Gatsby's SSR (Server Side Rendering) APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/ssr-apis/
 */

// eslint-disable-next-line no-unused-vars
import React from 'react';
import i18next from 'i18next';

import i18n from './src/locales/i18n';
import { I18nextProvider } from 'react-i18next';
import { PageContext } from './pageContext';

/**
 * Wrap all pages with a Translation provider and set the language on SSR time
 */
export const wrapRootElement = ({ element }) => {
    return <I18nextProvider i18n={i18n}>{element}</I18nextProvider>;
};

/**
 * Wrap all pages with a Translation provider and set the language on SSR time
 */
export const wrapPageElement = ({
    element, props
}) => {
    if (i18next.language !== props.pageContext.lang) {
        i18next.changeLanguage(props.pageContext.lang);
    }

    return <PageContext.Provider value={props.pageContext}>{element}</PageContext.Provider>;
};

const EVENT_START_CONVERSATION = 'DRIFT_START_CONVERSATION';

export const onRenderBody = ({ setHeadComponents }) => {
    const helpScoutSnippet = `
    const lang = document.documentElement.lang;
    !(function (e, t, n) { function a() { const e = t.getElementsByTagName('script')[0], n = t.createElement('script'); (n.type = 'text/javascript'), (n.async = !0), (n.src = 'https://beacon-v2.helpscout.net'), e.parentNode.insertBefore(n, e); } if (((e.Beacon = n = function (t, n, a) { e.Beacon.readyQueue.push({ method: t, options: n, data: a }); }), (n.readyQueue = []), 'complete' === t.readyState)) return a(); e.attachEvent ? e.attachEvent('onload', a) : e.addEventListener('load', a, !1); })(window, document, window.Beacon || function () { });

    if (lang === 'fr') {
        window.Beacon('init', 'ee5ecbf2-43f1-4558-b358-48b3ec9d03b0');
    } else {
        window.Beacon('init', '1e337330-5ffc-4dda-a7e1-e610600758e8');
    }

    window.Beacon('once', 'chat-started', () => {
        if (window.dataLayer) {
          window.dataLayer.push({'event': '${EVENT_START_CONVERSATION}'});
        }

        if (typeof window !== 'undefined' && window.gtag) {
              window.gtag('event', 'form send', {
                  event_category: 'form',
                  event_label: 'drift conversation'
              });
        }
    });
  `;

    const delayedSnippet = `
      window.helpScoutSnippetLoaded = false;
      window.helpScoutSnippetLoading = false;
      window.helpScoutSnippetLoader = function (callback) {
        if (!window.helpScoutSnippetLoaded && !window.helpScoutSnippetLoading) {
          window.helpScoutSnippetLoading = true;
          function loader(tmp) {
            ${helpScoutSnippet}
            window.helpScoutSnippetLoading = false;
            window.helpScoutSnippetLoaded = true;
            if(callback) {callback()}
          };
          setTimeout(
            function () {
              "requestIdleCallback" in window
                ? requestIdleCallback(function () {loader()})
                : loader();
            },
            2000
          );
        }
      }
      window.addEventListener('scroll',function () {window.helpScoutSnippetLoader()}, { once: true });
    `;

    setHeadComponents([
        <script
            key="plugin-drift"
            dangerouslySetInnerHTML={{ __html: delayedSnippet }}
        />
    ]);
};
